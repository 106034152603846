/* @font-face {
  font-family: "BrandonText-Bold";
  src: local("BrandonText-Bold"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.woff2) format("woff2");
  src: url(./resources/fonts/fonts/32CF0E_2_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.ttf) format("truetype");
} */

@font-face {
  font-family: "BrandonTextWeb-Regular";
  font-display: fallback;
  src: url(./resources/fonts/fonts/32CF0E_8_0_1.eot);
  src: url(./resources/fonts/fonts/32CF0E_8_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_8_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_8_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_8_0_1.ttf) format("truetype");
}

@font-face {
  font-family: "BrandonTextWeb-Thin";
  font-display: fallback;
  src: url(./resources/fonts/fonts/32CF0E_B_0_1.eot);
  src: url(./resources/fonts/fonts/32CF0E_B_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_B_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_B_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_B_0_1.ttf) format("truetype");
}

@font-face {
  font-family: "BrandonTextWeb-Bold";
  font-display: fallback;
  src: url(./resources/fonts/fonts/32CF0E_2_0_1.eot);
  src: url(./resources/fonts/fonts/32CF0E_2_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_2_0_1.ttf) format("truetype");
}

@font-face {
  font-family: "BrandonTextWeb-Light";
  font-display: fallback;
  src: url(./resources/fonts/fonts/32CF0E_5_0_1.eot);
  src: url(./resources/fonts/fonts/32CF0E_5_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_5_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_5_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_5_0_1.ttf) format("truetype");
}

@font-face {
  font-family: "BrandonTextWeb-Medium";
  font-display: fallback;
  src: url(./resources/fonts/fonts/32CF0E_9_0_1.eot);
  src: url(./resources/fonts/fonts/32CF0E_9_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_9_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_9_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_9_0_1.ttf) format("truetype");
}

@font-face {
  font-family: "BrandonTextWeb-Black";
  font-display: fallback;
  src: url(./resources/fonts/fonts/32CF0E_0_0_1.eot);
  src: url(./resources/fonts/fonts/32CF0E_0_0_1.eot?#iefix)
      format("embedded-opentype"),
    url(./resources/fonts/fonts/32CF0E_0_0_1.woff2) format("woff2"),
    url(./resources/fonts/fonts/32CF0E_0_0_1.woff) format("woff"),
    url(./resources/fonts/fonts/32CF0E_0_0_1.ttf) format("truetype");
}

@font-face {
  font-family: "Berkshire Swash";
  font-display: fallback;
  src: url(./resources/fonts/fonts/BerkshireSwash-Regular_1.ttf)
    format("truetype");
}

body {
  margin: 0;
  font-family: "BrandonTextWeb-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
